<template>
  <div class="home w-full h-full">
  
    <div class="spawnView h-4/5 pb-5 mx-2 lg:mx-16 rounded-md lg:rounded-md border border-2 border-[#565656] px-3 xl:px-6 pb-[8px]" style="background: linear-gradient(129.35deg, #0A0A0A 27.47%, #494949 146.79%);">
      <div class="h-[90%] overflow-y-auto overflow-x-hidden custom-scrollbar pr-2" ref="chatContainer">

        <div class="flex text-left py-5 px-5 rounded-xl bg-[#0E0E0E] text-white mb-2 mt-4">
          <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
            <img class="aspect-square w-full" src="../assets/chat/ppIA.png" alt="">
          </div>
          <div class="w-[96%] text-justify text-sm md:text-md md:pt-4 whitespace-pre-line">Hey, welcome to Wutensor, i'm an AI assistant made to help you answer any question.</div>
        </div>

        <div v-for="r in response.context" :key="r">
          <div v-if="r.role == 'user'" class="flex text-left py-5 px-5 rounded-xl border border-1 border-[#565656] text-white mb-2" style="background: linear-gradient(92.57deg, #0E0E0E 2.15%, #747474 256.04%);">
            <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
              <img class="aspect-square w-full rounded-full" src="../assets/chat/ppUser.jpg" alt="">
            </div>
            <p class="w-[96%] text-justify text-sm md:text-md md:pt-4 overflow-hidden">{{ r.content }}</p>
          </div>

          <div v-else class="flex text-left py-5 px-5 rounded-xl bg-[#0E0E0E] text-white mb-2">
            <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
              <img class="aspect-square w-full" src="../assets/chat/ppIA.png" alt="">
            </div>
            <div class="w-[96%] text-justify text-sm md:text-md md:pt-4" v-html="formattedContent(r.content)"></div>
          </div>
        </div>

        <div v-if="waitingForResponse" class="appearfirst flex text-left py-5 px-5 rounded-xl border border-1 border-[#565656] text-white mb-2" style="background: linear-gradient(92.57deg, #0E0E0E 2.15%, #747474 256.04%);">
          <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
            <img class="aspect-square w-full rounded-full" src="../assets/chat/ppUser.jpg" alt="">
          </div>
          <div class="w-[96%] text-justify text-sm md:text-md md:pt-4 whitespace-pre-line">{{ waitingForResponse }}</div>
        </div>

        <div v-if="waitingForResponse" class="appearsec flex text-left py-5 px-5 rounded-xl bg-[#0E0E0E] text-white mb-2">
          <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
            <img class="aspect-square w-full" src="../assets/chat/ppIA.png" alt="">
          </div>
          <div class="w-[96%] text-justify text-sm md:text-md md:pt-4">Loading response...</div>
        </div>

        <div v-if="error" class="appearsec flex text-left py-5 px-5 rounded-xl bg-[#0E0E0E] text-white mb-2">
          <div class="h-full w-8 md:w-[45px] mr-5 flex justify-around">
            <img class="aspect-square w-full" src="../assets/chat/ppIA.png" alt="">
          </div>
          <div class="w-[96%] text-justify text-sm md:text-md text-red-400 md:pt-4">Too many requests. The number of requests is limited for non $WUT holders.</div>
        </div>
      </div>

      <div class="h-[8%] min-h-10 mb-3 flex flex-col justify-center border border-2 border-[#565656] rounded-xl overflow-hidden">
        <div class="w-full flex justify-between">
          <input v-model="content"  placeholder="Write a message..." class="w-[96%] outline-none h-full text-white text-xl p-2 rounded-2xl px-4 resize-none bg-transparent" name="wutmessage" id="wutmessage" @keydown.enter="sendMessage"/>

          <svg @click="sendMessage" class="h-10 p-3 cursor-pointer" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8553 9.41915C19.6634 9.07281 19.6634 7.92719 18.8553 7.58085L1.981 0.348997C1.1986 0.013685 0.38171 0.746919 0.630875 1.56086L2.66549 8.20728C2.7239 8.39806 2.7239 8.60194 2.66549 8.79271L0.630874 15.4391C0.381709 16.2531 1.1986 16.9863 1.98099 16.651L18.8553 9.41915Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
export default {
  name: 'HomeView',
  components: {
  },
  data(){
    return {
      response: {
        response: '', 
        context: [],
      },
      waitingForResponse: null,
      error: null,
    }
  },
  methods: {


    formattedContent(html) {
    html = this.escapeHtml(html);

    const codeBlockRegex = /```([\s\S]*?)```/g;
    let lastIndex = 0;
    let formattedText = '';

    let match;
    while ((match = codeBlockRegex.exec(html)) !== null) {
        const textBefore = html.substring(lastIndex, match.index);
        formattedText += textBefore; 

        let codeBlockContent = match[1];

        codeBlockContent = codeBlockContent.replace(/^\w+\s*/, '');

        codeBlockContent = codeBlockContent.replace(/\n/g, '<br>');

        formattedText += `<div class="bg-black border-1 border-gray-800 p-4 my-2  overflow-x-hidden">${codeBlockContent}</div>`;

        lastIndex = match.index + match[0].length;
    }

    formattedText += html.substring(lastIndex);

    return formattedText;
},

escapeHtml(text) {
    const div = document.createElement('div');
    div.textContent = text;
    return div.innerHTML;
},





    async sendMessage() {
      if(!this.content) return;
      try {
        this.waitingForResponse = this.content;
        
        this.$nextTick(() => {
          this.scrollToBottom();
        });

        const msg = this.content;
        this.content = '';

        const response = await axios.post(this.$parent.$parent.target + '/api/chat', {
          message: msg,
          context: this.response.context
        });

        this.waitingForResponse = null;

        this.response.response = response.data.response;
        this.response.context = response.data.context;

        
        this.$nextTick(() => {
          this.scrollToBottom();
        });
       } catch (error) {
        
        this.error = error.message;
        console.error('Error sending message:', error);
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },

    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

}
</script>


<style scoped>




.inputt{
  border: 2px solid;

border-image-source: linear-gradient(171.01deg, rgba(0, 0, 0, 0.69) -90%, rgba(153, 153, 153, 0.69) 231.49%);
background: linear-gradient(129.35deg, rgba(10, 10, 10, 0.9) 27.47%, rgba(73, 73, 73, 0.9) 146.79%);


}

.appearfirst{
  opacity: 0;
  animation: appear 0.2s forwards;  

}

.appearsec{
  opacity: 0;
  animation: appearsec 0.5s forwards;  

}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearsec {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Largeur de la scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #FFFFFF; /* Couleur de la thumb */
  border-radius: 4px; /* Arrondi de la thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #3F3F3F; /* Couleur de la track */
  border-radius: 10px;
  margin: 1px;
  margin-left: 5px;
}




</style>
