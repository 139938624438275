<template>
  <div class="bg-[#0F0F0F] flex overflow-hidden ">
    <div class="z-20">
      <SideBar />
    </div>
    <div class="w-full h-screen md:max-h-screen overflow-hidden pl-0 lg:pl-[140px] z-10" :style="{ 'max-height': containerHeight + 'px' }">
      <navBar></navBar>
      <router-view class="z-10 overflow-hidden w-full h-full" />
    </div>
  </div>
</template>

<script>
import Web3 from 'web3';
import SideBar from './components/sideBar.vue';
import navBar from './components/work-navBar.vue';
export default {
  name: 'App',
  components: {
    navBar,
    SideBar,
  },
  data() {
    return {
      web3: null,
      account: null,
      containerHeight: 930,
      target: 'https://api.wutensor.com',
    };
  }, 

  mounted() {
    this.setContainerHeight();
    window.addEventListener('resize', this.setContainerHeight);


  },


  methods: {
    setContainerHeight() {
      const viewportHeight = window.innerHeight;

      this.containerHeight = viewportHeight;
      

    },


    disconnectWallet() {
      this.web3 = null;
      this.account = null;

      if (window.ethereum) {
        window.ethereum.removeAllListeners('accountsChanged');
      }

      console.log('Wallet disconnected.');
    },


    async connectWallet() {
  try {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      this.web3 = new Web3(window.ethereum);

      this.account = accounts[0]; 

      window.ethereum.on('accountsChanged', (newAccounts) => {
        this.account = newAccounts[0];
      });

    }
    else if (window.web3) {
      this.web3 = new Web3(window.web3.currentProvider);
      this.web3.eth.getAccounts((err, accounts) => {
        if (accounts.length > 0) {
          this.account = accounts[0];
        } else {
          console.error('No accounts found using legacy web3 provider.');
        }
      });
    }
    else {
      console.error('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  } catch (error) {
    console.error('Error connecting wallet:', error);
  }
},

  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

input:focus {
  outline: none; /* supprime le contour */
  box-shadow: none; /* supprime l'ombre */
  border-color: initial; /* réinitialise la couleur de bordure */
  /* autres propriétés que vous souhaitez réinitialiser ou supprimer */
}


.spawnView {
  animation: fadeIn 0.5s;
}

</style>
