<template>
  <div class="select-none z-40 mt-5 lg:my-6 h-10 lg:h-16 mx-2 lg:mx-16 rounded flex justify-around flex-col border border-2 border-[#565656]" style="background: linear-gradient(92.57deg, #0E0E0E 2.15%, #747474 256.04%);">
    <div class="flex button-group hidden lg:flex select-none">
      <router-link to="/"><button class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Conversational</button></router-link>
      <router-link to="/gen/image"><button class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Image</button></router-link>
      <router-link to="/gen/speech"><button class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Audio</button></router-link>
      <router-link class="w-20" @mouseenter="videoHover = true; scrapHover = false; computeHover = false;" @mouseleave="videoHover = false" to="">
          <button v-if="!videoHover" class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Video</button>
          <button v-else class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>
        <router-link class="w-36" @mouseenter="scrapHover = true; videoHover = false; computeHover = false;" @mouseleave="scrapHover = false" to="">
          <button v-if="!scrapHover" class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Data Scraping</button>
          <button v-else class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>
        <router-link class="w-20" @mouseenter="computeHover = true; videoHover = false; scrapHover = false;" @mouseleave="computeHover = false" to="">
          <button v-if="!computeHover" class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Compute</button>
          <button v-else class="button px-6 rounded-xl text-white h-8 lg:h-12 text-sm lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>  
      <div v-if="!this.$parent.account" @click="this.$parent.connectWallet()" class="select-none bg-[#FFFFFF] text-lg cursor-pointer flex justify-around flex-col rounded-lg font-bold select-none w-40 ml-auto mr-4 text-sm">
        Connect Wallet
      </div>
      <div v-else @click="this.$parent.disconnectWallet()" class=" text-sm select-none bg-[#FFFFFF] text-lg cursor-pointer flex justify-around flex-col rounded-lg font-bold w-48 ml-auto mr-4 select-none">
        Disconnect (...{{ this.$parent.account.slice(-4) }})
      </div>
    </div>

    <div class="w-full flex lg:hidden relative " @click="this.mainMenu = !this.mainMenu">
      <svg v-if="!mainMenu"  viewBox="0 0 24 25" class="w-5 ml-3" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0H5.42069C2.47477 0 0.0704838 2.11361 0.00151572 4.7357H18.5815C21.5268 4.7357 23.931 2.62209 24 0Z"/>
        <path d="M14.9022 4.7357C11.8653 4.79686 9.41452 7.02826 9.41452 9.75989V14.2745C9.45355 16.1794 7.83343 18.2184 5.6166 18.1698C5.5734 18.1698 5.53083 18.1692 5.48763 18.167V12.3745C5.48763 9.64221 3.03699 7.41092 0 7.34976V20.4289C0.212588 25.1807 6.22203 26.1214 9.66917 23.7447C7.28699 24.037 5.44279 21.9067 5.48763 19.8702V18.3078C9.04959 20.539 14.7239 19.3755 14.9022 14.8356V4.7357Z"/>
        <path d="M17.7907 12.3744V19.8701C17.8351 21.9063 15.9906 24.0371 13.6084 23.7446C17.0642 26.1254 23.0667 25.1733 23.2776 20.4311V7.34976C20.2407 7.41092 17.7906 9.64289 17.7906 12.3745L17.7907 12.3744Z"/>
      </svg>
      <svg v-else  class="cursor-pointer ml-2 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53035 18.5303C6.23745 18.8232 5.76258 18.8232 5.46969 18.5303C5.17679 18.2374 5.17679 17.7626 5.46968 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z" fill="#ffffff"></path> </g></svg>

      <div class="flex">
        <h3  class="text-white ml-3 ">Menu</h3>
        <svg v-if="!mainMenu" class="w-2 h-2 rotate-180 my-auto ml-1" fill="#FFFFFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 123.959 123.959" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742 z"></path> </g> </g></svg>      
      </div>

      <div v-if="!this.$parent.account" @click="this.$parent.connectWallet()" class="select-none bg-[#FFFFFF] h-full text-sm cursor-pointer flex justify-around flex-col rounded-md font-bold w-28 ml-auto mr-1">
        Connect Wallet
      </div>
      <div v-else @click="this.$parent.disconnectWallet()" class=" select-nonebg-[#FFFFFF] h-full text-sm cursor-pointer flex justify-around flex-col rounded-md font-bold w-36 ml-auto mr-1">
        Disconnect (...{{ this.$parent.account.slice(-4) }})
      </div>
    </div>
    <div v-if="mainMenu" class="bg-[#0f0f0f] absolute h-full w-screen z-10 -bottom-16 left-0 px-10 pt-10 select-none">
      <div class="my-4 border border-gray-100 border-2 bg-[#0E0E0E] w-full p-4 rounded-xl cursor-pointer flex  h-16 my-4 text-[#AAAAAA] hover:scale-[1.02] transition-all" :class="{'bg-white text-black' : this.selected == 0}">
          <div class="h-full w-12 flex justify-around">
            <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"  class="h-full py-1  w-6 lg:w-12 text-red-100" fill="white">
              <path d="M24 0H5.42069C2.47477 0 0.0704838 2.11361 0.00151572 4.7357H18.5815C21.5268 4.7357 23.931 2.62209 24 0Z" />
              <path d="M14.9022 4.7357C11.8653 4.79686 9.41452 7.02826 9.41452 9.75989V14.2745C9.45355 16.1794 7.83343 18.2184 5.6166 18.1698C5.5734 18.1698 5.53083 18.1692 5.48763 18.167V12.3745C5.48763 9.64221 3.03699 7.41092 0 7.34976V20.4289C0.212588 25.1807 6.22203 26.1214 9.66917 23.7447C7.28699 24.037 5.44279 21.9067 5.48763 19.8702V18.3078C9.04959 20.539 14.7239 19.3755 14.9022 14.8356V4.7357Z" />
              <path d="M17.7907 12.3744V19.8701C17.8351 21.9063 15.9906 24.0371 13.6084 23.7446C17.0642 26.1254 23.0667 25.1733 23.2776 20.4311V7.34976C20.2407 7.41092 17.7906 9.64289 17.7906 12.3745L17.7907 12.3744Z"/>
            </svg>
          </div>
          <h3 @click="this.mainMenu =false; this.$router.push('/')" class="h-full text-xl text-white ">Workstation</h3>
        </div>
        <div class=" my-4 border border-gray-100 border-2 bg-[#0E0E0E] w-full p-4 rounded-xl cursor-pointer flex  h-16 my-4 text-[#AAAAAA] hover:scale-[1.02] transition-all" :class="{'bg-white text-black' : this.selected == 0}">
          <div class="h-full w-12 flex justify-around">
            <svg  viewBox="0 0 27 25" xmlns="http://www.w3.org/2000/svg"  class="h-full py-1  w-6 lg:w-12 text-red-100" fill="white" >
              <path d="M19.4062 0H7.59375C5.57976 0 3.64826 0.803569 2.22416 2.23393C0.800053 3.66429 0 5.60428 0 7.62712V20.7627C0 21.8865 0.444474 22.9643 1.23564 23.7589C2.02681 24.5536 3.09987 25 4.21875 25H22.7812C23.3353 25 23.8839 24.8904 24.3957 24.6775C24.9075 24.4645 25.3726 24.1524 25.7644 23.7589C26.1561 23.3655 26.4669 22.8983 26.6789 22.3843C26.8909 21.8702 27 21.3192 27 20.7627V7.62712C27 5.60428 26.1999 3.66429 24.7758 2.23393C23.3517 0.803569 21.4202 0 19.4062 0ZM23.625 20.7627C23.625 20.9875 23.5361 21.203 23.3779 21.362C23.2196 21.5209 23.005 21.6102 22.7812 21.6102H4.21875C3.99497 21.6102 3.78036 21.5209 3.62213 21.362C3.4639 21.203 3.375 20.9875 3.375 20.7627V14.4068H8.4375V14.8305C8.4375 15.7295 8.79308 16.5918 9.42601 17.2275C10.059 17.8632 10.9174 18.2203 11.8125 18.2203H15.1875C16.0826 18.2203 16.941 17.8632 17.574 17.2275C18.2069 16.5918 18.5625 15.7295 18.5625 14.8305V14.4068H23.625V20.7627ZM11.8125 14.8305V12.2881C11.8125 11.8386 11.9903 11.4075 12.3068 11.0897C12.6232 10.7718 13.0524 10.5932 13.5 10.5932C13.9476 10.5932 14.3768 10.7718 14.6932 11.0897C15.0097 11.4075 15.1875 11.8386 15.1875 12.2881V14.8305H11.8125ZM23.625 11.0169H18.398C18.1134 9.93067 17.4787 8.96961 16.593 8.2838C15.7073 7.598 14.6204 7.22607 13.5021 7.22607C12.3838 7.22607 11.2969 7.598 10.4112 8.2838C9.52551 8.96961 8.89082 9.93067 8.60625 11.0169H3.375V7.62712C3.375 6.50332 3.81947 5.42555 4.61064 4.6309C5.40181 3.83626 6.47487 3.38983 7.59375 3.38983H19.4062C20.5251 3.38983 21.5982 3.83626 22.3894 4.6309C23.1805 5.42555 23.625 6.50332 23.625 7.62712V11.0169Z" />
            </svg>
          </div>
          <h3 class="h-full text-xl text-white ">Staking</h3>
          <span class="rounded-lg bg-[#D9D9D9] px-3 ml-3 my-1 text-center flex flex-col justify-center text-black">soon</span>
        </div>

        <div @mouseenter="this.dev=true" @mouseleave="this.dev = false" class="my-4 overflow-hidden border border-gray-100 border-2 mt-4 h-16 transition-all hover:h-60 text-[#AAAAAA] rounded-xl" :class="{'text-white' : this.dev}">
          <div class=" rounded-lg pb-3" >
          <div class="bg-[#0E0E0E] w-full p-4 rounded-xl cursor-pointer flex color-white  h-16  transition-all" :class="{'bg-white text-black' : this.dev }">
          <div class="h-full w-12 flex justify-around">
            <svg viewBox="0 0 32 25" xmlns="http://www.w3.org/2000/svg"   class="h-full py-1  w-6 lg:w-12 text-red-100" fill="white" >
              <path d="M28.8 0H3.2C2.35131 0 1.53737 0.32924 0.937258 0.915291C0.337142 1.50134 0 2.2962 0 3.125V21.875C0 22.7038 0.337142 23.4987 0.937258 24.0847C1.53737 24.6708 2.35131 25 3.2 25H28.8C29.6487 25 30.4626 24.6708 31.0627 24.0847C31.6629 23.4987 32 22.7038 32 21.875V3.125C32 2.2962 31.6629 1.50134 31.0627 0.915291C30.4626 0.32924 29.6487 0 28.8 0ZM7.5312 19.8547C7.22944 20.1393 6.82527 20.2968 6.40576 20.2932C5.98624 20.2897 5.58494 20.1254 5.28829 19.8357C4.99164 19.546 4.82337 19.1541 4.81972 18.7444C4.81608 18.3347 4.97735 17.94 5.2688 17.6453L10.5374 12.5L5.2688 7.35469C5.11598 7.21055 4.99409 7.03814 4.91024 6.84751C4.82638 6.65688 4.78225 6.45184 4.7804 6.24438C4.77855 6.03691 4.81904 5.83116 4.89949 5.63913C4.97993 5.44711 5.09874 5.27265 5.24897 5.12595C5.3992 4.97924 5.57784 4.86322 5.77447 4.78465C5.97111 4.70609 6.1818 4.66656 6.39424 4.66836C6.60669 4.67016 6.81664 4.71326 7.01185 4.79515C7.20705 4.87704 7.38361 4.99608 7.5312 5.14531L13.9312 11.3953C14.0798 11.5403 14.1977 11.7125 14.2782 11.9021C14.3586 12.0916 14.4001 12.2948 14.4001 12.5C14.4001 12.7052 14.3586 12.9084 14.2782 13.0979C14.1977 13.2875 14.0798 13.4597 13.9312 13.6047L7.5312 19.8547ZM25.6 20.3125H16C15.5757 20.3125 15.1687 20.1479 14.8686 19.8549C14.5686 19.5618 14.4 19.1644 14.4 18.75C14.4 18.3356 14.5686 17.9382 14.8686 17.6451C15.1687 17.3521 15.5757 17.1875 16 17.1875H25.6C26.0243 17.1875 26.4313 17.3521 26.7314 17.6451C27.0314 17.9382 27.2 18.3356 27.2 18.75C27.2 19.1644 27.0314 19.5618 26.7314 19.8549C26.4313 20.1479 26.0243 20.3125 25.6 20.3125Z" />
            </svg>
          </div>
          <h3 class="h-full  text-xl text-white  ">Dev </h3>
        </div>
        <div class="px-4">
          <div class="bg-[#0E0E0E] w-full p-4 rounded-xl cursor-pointer flex color-white h-16 mt-2 text-white border border-gray-100" >
            <div class="h-full flex justify-around">
              <svg viewBox="0 0 26 25"  xmlns="http://www.w3.org/2000/svg" class="h-full py-1 mx-1 mr-2 w-12 " style="fill: white" >
                <path d="M23.4902 18.0563C23.363 17.93 23.1952 17.8597 23.0169 17.8597H21.2549V16.1631C21.2549 15.9861 21.1841 15.8168 21.057 15.6931L19.7853 14.4301C19.6581 14.3038 19.4903 14.2335 19.312 14.2335H17.4018V12.8299C17.4018 12.6528 17.331 12.4836 17.2039 12.3599L15.965 11.1294C16.5051 9.86908 16.7096 8.4863 16.5588 7.10358C16.1498 3.37972 13.1291 0.411167 9.37726 0.0401811C6.95057 -0.199393 4.5712 0.640426 2.85618 2.3435C1.14137 4.04657 0.298398 6.40968 0.54095 8.82359C0.917213 12.5371 3.89714 15.533 7.63225 15.947C9.01798 16.1032 10.4076 15.904 11.6728 15.3741L21.1671 24.8034C21.2943 24.9297 21.4621 25 21.6404 25H24.8288C25.1985 25 25.5 24.7005 25.5 24.3334V20.3335C25.5 20.1564 25.4292 19.9872 25.302 19.8635L23.4902 18.0563ZM6.87303 8.82995C5.48337 8.82995 4.35589 7.71019 4.35589 6.33004C4.35589 4.94989 5.48337 3.83013 6.87303 3.83013C8.2627 3.83013 9.39018 4.94989 9.39018 6.33004C9.39018 7.71019 8.2627 8.82995 6.87303 8.82995Z" />
              </svg>
            </div>
            <h3 class="h-full text-xl text-white ">API</h3>
            <span class="rounded-lg bg-[#D9D9D9] px-2 text-sm ml-3 my-1 text-center flex flex-col justify-center text-black "  >soon</span>

          </div>
        </div>
        <div class="px-4">
          <div class=" w-full p-4 rounded-xl cursor-pointer flex color-white  h-16 mt-2 grad-black hover:grad-white text-white  border border-gray-100" >
            <a class="w-full flex" href="https://docs.wutensor.com/" target="_blank"><div class="h-full flex justify-around">
              <svg class="h-full py-1 mx-1 mr-2 w-12 "  viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.8279 3.61842V8.15789L16.8427 7.73026L12.8279 3.61842Z" fill="white"/>
                <path d="M11.9466 9.14474V3.02632H0V25H17.365V8.58553L11.9466 9.14474Z" fill="white"/>
                <path d="M17.4303 0.55921V5.13158L21.4777 4.70395L17.4303 0.55921Z" fill="white"/>
                <path d="M16.549 6.08553V0H4.63502V2.13816H12.632L18.2463 7.92763V21.9737H22V5.52632L16.549 6.08553Z" fill="white"/>
              </svg>
            </div>
            <h3 class="h-full text-xl">Docs</h3></a>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <div class="flex select-none lg:hidden border border-2 border-[#565656] my-2 rounded py-2 mx-2 pl-4 ">
      <svg v-if="workMenu == false" v-on:click="workMenu = true" class="w-6 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 18L20 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
        <path d="M4 12L20 12" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
        <path d="M4 6L20 6" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
      </svg>
      <svg v-else v-on:click="workMenu = false;" class="cursor-pointer w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53035 18.5303C6.23745 18.8232 5.76258 18.8232 5.46969 18.5303C5.17679 18.2374 5.17679 17.7626 5.46968 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z" fill="#ffffff"></path> </g></svg>
      <h1 @click="this.workMenu = !this.workMenu" class="flex flex-col justify-center ml-2 text-white">WorkStation</h1>
      
    </div>
    <div v-if="workMenu" class="absolute top-12 z-[10] bg-[#0f0f0f] w-full h-screen opacity-0" :class="{ 'opacity-100': workMenu }">
      <div class="w-full h-full flex flex-col">
        <router-link @click="workMenu = false" to="/"><button class="button my-3 px-6 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Conversational</button></router-link>
        <router-link @click="workMenu = false"  to="/gen/image"><button class="button my-3 px-6 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Image</button></router-link>
        <router-link @click="workMenu = false"  to="/gen/speech"><button class="button my-3 px-6 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Audio</button></router-link>
        <router-link @mouseenter="videoHover = true" @mouseleave="videoHover = false" to="">
          <button v-if="!videoHover" class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Video</button>
          <button v-else class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg text-gray-400 lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>
        <router-link @mouseenter="scrapHover = true" @mouseleave="scrapHover = false" to="">
          <button v-if="!scrapHover" class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Data Scraping</button>
          <button v-else class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg text-gray-400 lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>
        <router-link @mouseenter="computeHover = true" @mouseleave="computeHover = false" to="">
          <button v-if="!computeHover" class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg lg:text-lg whitespace-nowrap">Compute</button>
          <button v-else class="button px-6 my-3 rounded-xl text-white h-8 lg:h-12 text-lg text-gray-400 lg:text-lg whitespace-nowrap">Soon</button>
        </router-link>      
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  data() {
    return {
      workMenu: false,
      mainMenu: false,
      videoHover: false,
      scrapHover: false,
      computeHover: false,
    }
  },
  updated(){
    if(this.mainMenu){
      this.workMenu = false
    }
    if(this.workMenu){
      this.mainMenu = false
    }
  }
}
</script>

<style scoped>
.button-group .button {
  transition: color 0.3s;
  position: relative;
}

/* Change all sibling buttons to gray when any button is hovered */
.button-group:hover .button {
  color: gray;
}

/* Keep the hovered button white */
.button-group .button:hover {
  color: white;
}



</style>
